import styled, { keyframes } from "styled-components";
import AutoPlay from "./AutoPlay";
import portraitSmall from "./assets/images/portraitSmall.jpg";
import React, { useState, useEffect } from "react";
import { tech_skills } from "./data";

const glitchAnimation1 = keyframes`
    0%,
    100% {
      opacity: 1;
      transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }

    20% {
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }

    30% {
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }

    40% {
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }

    50% {
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }

    55% {
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }

    60% {
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }

    65% {
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }

    70% {
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }

    80% {
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }

    85% {
      clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
    }

    95% {
      clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
    }
  `;

const glitchAnimation2 = keyframes`
0%,
    100% {
      opacity: 1;
      transform: translate3d(-10px, 0, 0);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }

    10% {
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }

    15% {
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }

    17% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }

    19% {
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }

    33% {
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }

    35% {
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }

    40% {
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }

    45% {
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }

    49% {
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }

    50% {
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }

    55% {
      clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
    }

    60% {
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }

    70% {
      clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
    }

    80% {
      clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
    }

    90% {
      clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
    }    
`;

const glitchAnimation3 = keyframes`
0%,
    100% {
      opacity: 1;
      transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }

    5% {
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }

    11% {
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }

    20% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }

    25% {
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }

    35% {
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }

    42% {
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }

    48% {
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }

    50% {
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }

    56% {
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }

    61% {
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }

    68% {
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }

    72% {
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }

    77% {
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }

    81% {
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }

    86% {
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }

    90% {
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }

    92% {
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }

    94% {
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
`;

const glitchAnimation4 = keyframes`
0%,
    5% {
      opacity: 0.2;
      transform: translate3d(10px, 5px, 0);
    }

    5.5%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
`;

const Bio = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = size.width < 480;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SectionWrapper>
      <BioDiv>
        <AvatarDiv>
          <div
            style={{ backgroundImage: `url(${portraitSmall})` }}
            className="c-glitch"
          >
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
            <div
              style={{ backgroundImage: `url(${portraitSmall})` }}
              className="c-glitch__img"
            />
          </div>
        </AvatarDiv>
        <BioDesWrapper>
          <BioP>
            Digital strategist / Frontend-focused Full Stack dev, I geek about
            analyzing the influence of technologies on human behavior.
            <br />
            <br />
            Prior to coding, I've developed a critical approach of technology
            through Digital Media / Communication Studies, gaining insights into
            the social, political, economic and legal impacts of technology.
            {/* This journey enabled me to 
            bridge the gap between technical expertise and real-world
            applications.*/}
            <br />
            <br />
            I've honed my expertise working alongside over a hundred of
            multidisciplinary board members, leaders and decision makers.
            {/*  While working with the Chamber of Commerce France Canada in Paris, I had
            the privilege of contributing to hosting conferences at the Canadian
            Embassy, featuring prominent figures such as Benjamin Smith,
            Director of Marketing at Air France, Peter Todd, former Dean of HEC
            Paris and Jean Charest, Former Premier of Quebec. Furthermore, I've
            been a passionate advocate for inclusive governance through
            initiatives endorsed by the Ministère de l'Immigration, de la
            Francisation et de l'Intégration. */}
            <br />
            <br />I approach my role in the tech industry intending to be a
            proactive changemaker.
          </BioP>
        </BioDesWrapper>
      </BioDiv>
      {/* {isMobile && ( */}
      {/* <>
        <TechSTitle>tech stack</TechSTitle>
        <TSMobileDiv>
          <GridContainer>
            {tech_skills.map((skill, i) => (
              <GridItem key={i}>
                <SkillImage
                  src={skill.imgSrc}
                  alt={`Icon of ${skill.skillName}`}
                />
                <p>{skill.skillName}</p>
              </GridItem>
            ))}
          </GridContainer>
        </TSMobileDiv>
      </> */}
      {/* )} */}
      {/* {!isMobile && (
        <TechSDiv>
          <ToolboxTitle>what's in my toolbox</ToolboxTitle>
          <AutoPlay />
        </TechSDiv>
      )} */}
      <SSTitle>soft skills</SSTitle>
      <Wrapper class="wrapper">
        <div class="scroll-cards">
          <article class="scroll-cards__item" aria-label="Wie - 1">
            <CardIcon>🫱🏼‍🫲🏾</CardIcon> <h4>inclusive leadership</h4>
            <p>
              Fostering an environment where the strengths of every individual
              are recognized and valued.
            </p>
          </article>
          <article class="scroll-cards__item" aria-label="Wie - 1">
            <CardIcon>♟️</CardIcon>
            <h4> problem-solving</h4>
            <p>
              Approaching challenges with an analytical mindset.
              {/* Identifying solutions by assessing situations from various angles,
              considering innovative ideas, and making conscious decisions to
              overcome obstacles effectively. */}
            </p>
          </article>
          <article class="scroll-cards__item" aria-label="Wie - 1">
            <CardIcon>🗣️</CardIcon>
            <h4> communication</h4>
            <p>Adapting communication to engage with various audiences.</p>
          </article>
          {/* <article class="scroll-cards__item" aria-label="Wie - 1">
            <h2>{isMobile && <>🧠 </>}fast learning</h2>
            <p>
              I have a natural ability to rapidly grasp new concepts and
              information, which allowed me to master full stack development
              within a short span of three months. I approach learning with
              curiousity and enthusiasm.
            </p>
          </article> */}
          <article class="scroll-cards__item" aria-label="Wie - 1">
            <CardIcon>👁️</CardIcon> <h4> multiperspective</h4>
            <p>
              Ensuring that diverse perspectives contribute to our collective
              success. Driving forward-thinking solutions.
            </p>
          </article>
        </div>
      </Wrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  .wrapper {
    max-width: 60em;
    margin: 0 auto;
  }

  .scroll-cards {
    counter-reset: card;
    position: relative;
    display: block;
    padding-bottom: 350px;
    /* padding-bottom: 40vh; */

    @media only screen and (max-width: 480px) {
      padding-bottom: 25vh;
    }

    @media only screen and (max-width: 768px) {
      right: 15px;
      width: 85vw;
      /* padding: 0; */
    }

    @media only screen and (min-width: 1600px) {
      padding-bottom: 850px;
    }
  }

  .scroll-cards > .scroll-cards__item + .scroll-cards__item {
    margin: 40vh auto auto auto;
    border-radius: 40px;
  }

  .scroll-cards h1 {
    position: sticky;
    top: 2rem;
    font-size: 2em;
    margin: auto;
    text-align: center;
  }

  .scroll-cards__item {
    --offset: 0.5em;
    color: white;
    position: sticky;
    top: max(16vh, 10em);
    padding: 2em 1.5em;
    min-height: 19em;
    background: #222222;
    background: rgb(27, 27, 27);
    box-shadow: 0px 2px 30px rgba(255, 203, 255, 0.2);
    width: calc(100% - 5 * var(--offset));
    margin: 50vh auto auto auto;
    border-radius: 40px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  h4 {
    font-size: 20px;
    margin-top: 40px;
    font-family: "Poppins", sans-serif;
    display: flex;
    /* justify-content: center; */
    /* margin: auto; */

    @media only screen and (min-width: 768px) {
      font-size: 30px;
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  h1 {
    /* font-family: "Poppins", sans-serif; */
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 2;

    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }
  }

  .scroll-cards__item:nth-of-type(0) {
    transform: translate(
      calc((0 - 1) * var(--offset)),
      calc((0 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(1) {
    transform: translate(
      calc((1 - 1) * var(--offset)),
      calc((1 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(2) {
    transform: translate(
      calc((2 - 1) * var(--offset)),
      calc((2 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(3) {
    transform: translate(
      calc((3 - 1) * var(--offset)),
      calc((3 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(4) {
    transform: translate(
      calc((4 - 1) * var(--offset)),
      calc((4 - 1) * var(--offset))
    );
  }
  .scroll-cards__item:nth-of-type(5) {
    transform: translate(
      calc((5 - 1) * var(--offset)),
      calc((5 - 1) * var(--offset))
    );
  }

  @media screen and (min-width: 37em) {
    h1 {
      font-size: 3em;
    }
    .scroll-cards__item {
      --offset: 1em;
      /* padding-left: 5em; */
      max-width: 42em;
    }
    .scroll-cards__item:before {
      counter-increment: card;
      content: "0" counter(card);
      /* display: flex; */
      display: none;
      align-items: center;
      justify-content: center;
      width: 2.75em;
      height: 2.75em;
      background: linear-gradient(
        to right,
        #b63f95 9.6%,
        #ec6a42,
        #f2b858,
        #92f8dd 93.6%
      );
      color: #fff;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 1.25em;
      top: 1.25em;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 62em) {
    .scroll-cards h1 {
      font-size: 3em;
    }
    .scroll-cards__item {
      --offset: 1.25em;
      max-width: 42em;
    }
  }
`;

const BioDiv = styled.div`
  border-radius: 40px;
  /* background: rgba(122, 122, 122, 0.1);
  backdrop-filter: blur(10px); */
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  display: flex;
  gap: 40px;
  /* align-items: center; */
  /* flex-direction: row-reverse; */

  @media only screen and (max-width: 950px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }

  .c-glitch,
  .c-glitch__img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @media only screen and (max-width: 480px) {
      border-radius: 50%;
      height: 100px;
      width: 100px;
    }
  }

  .c-glitch {
    height: calc(100vh - 200px);
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;

    &:hover {
      .c-glitch__img:nth-child(n + 2) {
        animation-duration: 2s;
        animation-delay: 0;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
      }

      .c-glitch__img:nth-child(2) {
        animation-name: ${glitchAnimation1};
      }

      .c-glitch__img:nth-child(3) {
        animation-name: ${glitchAnimation2};
      }

      .c-glitch__img:nth-child(4) {
        animation-name: ${glitchAnimation3};
      }

      .c-glitch__img:nth-child(5) {
        animation-name: ${glitchAnimation4};
        background-blend-mode: overlay;
        background-color: #af4949;
      }
    }
  }

  .c-glitch__img {
    background-blend-mode: none;
    background-color: transparent;
    height: calc(100% + 5px * 2);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: calc(100% + 10px * 2);

    @media only screen and (max-width: 480px) {
      width: 150px;
      height: 150px;
    }
  }

  .c-glitch__img:nth-child(n + 2) {
    opacity: 0;
  }
`;

const AvatarDiv = styled.div`
  /* border: greenyellow 3px solid; */
  margin: 0;
`;

const TechSDiv = styled.div`
  border-radius: 40px;
  text-align: center;
`;

const TechSTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin: auto auto 20px auto;

  @media only screen and (max-width: 480px) {
    font-size: 30px;
    margin: 4vh auto 8vh auto;
  }
`;

const SectionTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const BioDesWrapper = styled.div`
  border-radius: 40px;
  background: rgba(50, 50, 50, 0.5);
  padding: 40px;

  @media only screen and (max-width: 768px) {
    background-color: transparent;
    padding: 0;
    width: 100%;
  }
`;

const BioP = styled.p`
  line-height: 36px;

  font-weight: 300;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const TSMobileDiv = styled.div`
  margin-bottom: 8vh;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  height: 120px;

  @media only screen and (max-width: 767px) {
  }
`;

const SkillImage = styled.img`
  max-width: 70px;
  height: auto;
`;

const ToolboxTitle = styled.h2`
  margin-bottom: 10vh;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 35px;
`;

const SSTitle = styled.h3`
  font-size: 80px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    text-transform: uppercase;
    font-family: "Roboto Mono", monospace;
    margin: 50px 0 0 0;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-weight: 300;
    padding: 0;
  }
`;

const CardIcon = styled.span`
  font-size: 50px;
  padding: 0;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    font-size: 60px;
  }
`;

export default Bio;
