import React, { useEffect } from "react";
import styled from "styled-components";
import { codingProjects } from "./data";
import { Link } from "react-router-dom";
import { vincent_maufay_rC3S_d7Qi_8_unsplash } from "./assets/images/vincent_maufay_rC3S_d7Qi_8_unsplash.jpg";
import grainygradientpink from "./assets/images/grainygradientpink.jpeg";
import grainygradientblue from "./assets/images/grainygradientblue.jpeg";
import grainygradientyellow from "./assets/images/grainygradientyellow.jpeg";
import BingeBuddyCover from "./assets/images/bingebuddymobcover.jpg";
import OOG from "./assets/images/rickmortymobcover.jpg";
import CritterCover from "./assets/images/crittermobcover.jpg";
import SlingairCover from "./assets/images/slingairmobcover.jpg";
import SportsSavvyCover from "./assets/images/sportssavvymobcover.jpg";
import G30 from "./assets/images/312937982_485935996898657_265667181636748601_n.jpg";
import MYWU from "./assets/images/4b0031eb-0b35-410a-a551-511cfd6940d4.png";
import { NavLink } from "react-router-dom";
import logoproto from "./assets/images/vscodesportologowhite.png";

const ProjectsMobile = () => {
  const [showIndex, set] = React.useState(null);

  // Scroll to the top of the page when the component mounts or 'page' state changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageWrapper>
      <Container>
        {codingProjects.map((project, index) => (
          <Button
            key={index}
            type="button"
            onClick={() => {
              set((prev) => (prev === index ? null : index));
            }}
          >
            <Card>
              <Face className="face1">
                <Content>
                  <span className="stars"></span>
                  <h2 className={project.className}>{project.name}</h2>
                  {project.sub && (
                    <p className={project.className}>{project.sub}</p>
                  )}
                  <Link
                    key={index}
                    to={`/project/${project.id}`}
                    className={project.className}
                  >
                    View more +
                  </Link>{" "}
                </Content>
              </Face>

              <Face
                className={`face2 ${index === showIndex ? "show" : "hide"}`}
              />
            </Card>
          </Button>
        ))}
      </Container>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  /* background: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%); */
  margin: auto;
  padding: 50px 0 50px 0;
  /* background: rgba(161, 243, 209, 1); */
`;

const TitleDiv = styled.div`
  border-radius: 40px;
  margin: 0 auto 1vh auto;
  width: 100%;
  display: flex;

  padding: 10px;
`;

const Title = styled.h1`
  color: #d9d9d9;
  text-align: center;
  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto;
`;

const Container = styled.div`
  max-width: 80vw;
  max-height: 10vh;
  display: flex;
  flex-direction: column;
  gap: 6vh;
  margin: 0 auto;
  padding: 40px;

  @media only screen and (min-width: 700px) {
    max-width: 725px;
  }
`;

const Card = styled.div`
  top: 25%;
  position: relative;
  /* max-width: 925px; */
  max-width: 80vw;
  height: 26vh;
  margin: 0 auto;
  background: #000;
  border-radius: 15px;
  box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);

  top: auto;

  @media only screen and (min-width: 700px) {
    height: 415px;
  }
`;

const Face = styled.div`
  position: absolute;
  /* comment out the line bellow for a transition from bottom to top */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;

  .face1 {
    box-sizing: border-box;
    padding: 20px;

    h2 {
      margin: 0;
      padding: 0;
    }
  }

  &.face2.show {
    height: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: rgb(13, 13, 13);
    /* color: white; */
    /* font-family: "Italiana", serif; */
    margin-top: -20px;
  }
`;

const Content = styled.div`
  padding: 20px;

  p {
    font-size: 16px;
    line-height: 4vh;
  }

  .java {
    background-color: #fffc00;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .python {
    background-color: #00fffc;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .cSharp {
    background-color: #fc00ff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Button = styled.button`
  background: none;
  padding: 0;
  cursor: pointer;

  &:nth-child(1) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #fffc00 0%,
      #fc00ff 45%,
      #00fffc 100%
    ); */
    background-image: url(${BingeBuddyCover});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  &:nth-child(2) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #fc00ff 0%,
      #00fffc 45%,
      #fffc00 100%
    ); */
    background-image: url(${CritterCover});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  &:nth-child(3) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #00fffc 0%,
      #fc00ff 45%,
      #fffc00 100%
    ); */
    /* background-image: url("https://images.unsplash.com/photo-1646038572829-c724d3bad7b7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2000&q=80"); */
    background-image: url(${SlingairCover});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  &:nth-child(4) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #fffc00 0%,
      #fc00ff 45%,
      #00fffc 100%
    ); */
    /* background-image: url("https://images.unsplash.com/photo-1646038572829-c724d3bad7b7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2000&q=80"); */
    background-image: url(${SportsSavvyCover});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  &:nth-child(5) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #fc00ff 0%,
      #00fffc 45%,
      #fffc00 100%
    ); */
    /* background-image: url("https://images.unsplash.com/photo-1646038572829-c724d3bad7b7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2000&q=80"); */
    background-image: url(${OOG});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    /* padding-left: 40px; */
    text-align: center;
  }

  &:nth-child(6) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #00fffc 0%,
      #fc00ff 45%,
      #fffc00 100%
    ); */
    /* background-image: url("https://images.unsplash.com/photo-1646038572829-c724d3bad7b7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2000&q=80"); */
    background-image: url(${G30});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  &:nth-child(7) .face2 {
    /* background-image: linear-gradient(
      40deg,
      #fffc00 0%,
      #fc00ff 45%,
      #00fffc 100%
    ); */
    background-image: url(${MYWU});
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }
`;

const LogoDiv = styled.div`
  top: -10px;
  left: 7vw;
  z-index: 2;
  position: relative;

  /* extraWide viewport */
  @media only screen and (min-width: 1640px) {
    margin: auto 120px;
    margin-top: 58px;
  }

  @media only screen and (max-width: 767px) {
    top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    left: 0;
    width: 100%;
  }
`;

const Logo = styled.a`
  font-family: Italiana;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.07);

  &:hover {
    text-decoration: none;
    color: black;
    color: white;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
    color: white;
    /* position: relative; */
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
`;

export default ProjectsMobile;
