import styles from "./Overlay.module.css";
import hand3d from "./assets/images/hand3d.png";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AutoPlay from "./AutoPlay";
import { tech_skills } from "./data";
import human_robot from "./assets/images/human_robot.jpg";

const Emoji = (props) => (
  <span
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);
export default Emoji;

export function Overlay() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = size.width < 1272;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <OverlayWrapper>
      <section
        role="main"
        aria-label="Presentation section"
        style={{ height: "100vh" }}
        className={styles.sectionHero}
      >
        <header className={styles.header}>
          {/* Voice reader text */}
          <h1 className={styles.sronly}>
            Virginie-Sankara CD's portfolio website.
          </h1>
          <p className={styles.sronly}>
            Hi ! I'm Virginie CD Full Stack developer. My purpose is to empower
            through technology. If you're hearing this message, it means that
            you are a Very Important User ! I wish you a great navigation on my
            portfolio.
          </p>
          {/* <p className={styles.nameV} aria-hidden="true">
            Virginie-Sankara Cantin-Diarra
          </p> */}
          {/* <h1>Transforming technology, Elevating society</h1> */}
          <h1>VSCD</h1>
          {/* <h2>Digital Expert + Full Stack Dev</h2> */}
          <div className={styles.expertise}>
            <span>Code</span>
            <span>Communication</span>
            <span>Digital Strategy</span>
          </div>
          {/* <h3>Innovation meets purpose</h3> */}
          <h3>
            Solving, Innovating and Communicating Through Empowering Technology
          </h3>
        </header>

        <svg
          className="bounce"
          width="24"
          height="56.8"
          viewBox="0 0 30 71"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Scroll down</title>
          <rect
            x="1.5"
            y="1.5"
            width="21.6"
            height="36"
            rx="10.8"
            stroke="#cdd1f0"
            strokeOpacity="0.6"
            strokeWidth="2.4"
          />
          <path
            d="M15 9.6V16"
            stroke="#cdd1f0"
            strokeOpacity="0.6"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
          <path
            d="M6.4 43.2L12.1 48.4L17.8 43.2"
            stroke="#cdd1f0"
            strokeOpacity="0.6"
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.4 50.4L12.1 55.6L17.8 50.4"
            stroke="#cdd1f0"
            strokeOpacity="0.6"
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </section>

      <section
        role="region"
        aria-label="Quick introduction"
        className={styles.sectionLeft}
      >
        <div className={styles.intro}>
          <div className={styles.sronly}>
            <p>
              Montreal-based Digital strategist, I geek about analyzing the
              influence of technologies on human behavior. Fluent in french,
              english, HTML/CSS, Javascript, I bring a keen awareness of the
              complex dynamics between society and technological advancements,
              as well as the technical skills to conceive and develop
              innovative, conscious, and sustainable applications.
            </p>
            <p>
              My favorite app is Be My Eyes, because it uses technology to
              connect people with visual disabilities with sighted volunteers
              who can help with day-to-day tasks.
            </p>
          </div>
          <h3 aria-hidden="true" className={styles.quickIntro}>
            {/* Innovate with Purpose, Thrive with Inclusion */}
            🖖🏾 Bonjour! Hi! I ni ce!
          </h3>

          <BonjourHi aria-hidden="true">
            <p>
              Montreal-based tech, I'm fluent in french, english, HTML/CSS and
              JavaScript.
              {/*I geek about analyzing the influence of
              technologies on human behavior.*/}
            </p>
          </BonjourHi>
        </div>

        {/* <p className={styles.bemyeyes} aria-hidden="true">
          (on the right,{" "}
          <a
            href="https://www.bemyeyes.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i>Be My Eyes</i>
          </a>
          , my favorite platform that uses technology to connect people with
          visual disabilities with sighted volunteers who can help with
          day-to-day tasks.)
        </p> */}
      </section>

      {/* <section
        role="region"
        aria-label="Quick introduction"
        className={styles.sectionTechS}
      > */}
      {/* <div>
        <div className={styles.sectionTechS}>
          <h2>TECH STACK</h2>
          <div className={styles.AutoPlay}>
            <AutoPlay></AutoPlay>
          </div>
        </div>
      </div>
       </section> */}

      <section role="region" aria-label="Quote" className={styles.sectionQuote}>
        <div className={styles.squarescontainer}>
          <div className={styles.square2}>
            <div className={styles.sectionQuote}>
              <p className={styles.quote} aria-hidden="true">
                Fostering critical thinking about the influence of technologies
                on human behavior, I bring a keen
                <span className={styles.glow}> awareness </span>to the
                deployment of solutions, along with
                <span className={styles.glow}> full stack skills </span>to
                conceive and develop innovative, conscious and
                <span className={styles.glow}> sustainable </span>applications.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        role="region"
        aria-label="Tech Stack"
        className={styles.sectionTechStack}
      >
        <h2 className={styles.toolboxTitle}>Tech Stack </h2>
        <div className={styles.mobileDiv}>
          <div className={styles.gridContainer}>
            {tech_skills.map((skill, i) => (
              <div className={styles.gridItem} key={i}>
                <img
                  className={styles.skillImage}
                  src={skill.imgSrc}
                  alt={`Icon of ${skill.skillName}`}
                />
                <p>{skill.skillName}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <br />
      <br />
      <br />
      <br />
      <br />
      {isMobile && (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}

      <section
        role="region"
        aria-label="Call to Action Section"
        className={styles.section3}
      >
        <div className={styles.ctadiv}>
          <p className={styles.cta}>
            Let's join forces to innovate
            <span className={styles.rocketEm}>🚀</span>
          </p>{" "}
          {/* <Link className="btn" to="/about" rel="about">
            <span>ABOUT</span>
          </Link>
          <br aria-hidden="true" />
          <Link className="btn" to="/projects" rel="projects">
            <span>WORK</span>
          </Link>
          <br aria-hidden="true" /> */}
          <Link className="btn" to="/contact" rel="contact">
            <span>CONTACT</span>
          </Link>
        </div>
      </section>
    </OverlayWrapper>
  );
}

const OverlayWrapper = styled.div`
  @media only screen and (max-width: 1272px) {
    /* background-color: green; */
    justify-content: center;
    align-items: center;
    /* width: 87vw; */
    padding: 0;
    margin: 0;
    /* background-color: pink; */
    /* padding-left: 0; */
  }
`;

const LogoWrapper = styled.div`
  /* top: 5vh;
  left: 7vw; */
  margin-top: 40px;
  margin-left: 100px;
  z-index: 2;
  position: absolute;
  display: none;

  @media only screen and (max-width: 1000px) {
    /* margin-left: 0; */
  }
`;

const Logo = styled.a`
  color: black;
  font-family: Italiana;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  /* background: rgba(217, 217, 217, 0.9); */

  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);

  &:hover {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 1272px) {
    font-size: 20px;
    padding: 0;
    /* position: relative; */
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 1272px) {
    /* width: 30px; */
    height: auto;
  }

  @media only screen and(max-width: 780px) {
    display: none;
  }
`;

const BonjourHi = styled.div`
  display: block;

  p {
    line-height: 40px;
    font-size: 20px;
    padding-top: 20px;
    font-weight: 400;
    /* font-family: "Roboto Mono", monospace; */

    @media only screen and (min-width: 1640px) {
      font-size: 20px;
      line-height: 35px;
    }
  }
`;
const TechSDiv = styled.div`
  /* border-radius: 40px; */
  text-align: center;
  width: 100vw;
  margin-left: -100px;
  margin-top: 130px;

  @media only screen and (max-width: 1272px) {
    text-align: center;
    width: 100vw;
    margin-left: -100px;
    margin-top: 130px;
  }
`;
const ToolboxTitle = styled.h2`
  margin-bottom: 100px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 35px;
`;
