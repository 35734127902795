import React, { useState, useEffect } from "react";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import ProjectDetails from "./ProjectDetails";
import Contact from "./Contact";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import Menu from "./Menu";
import styled from "styled-components";
import logoproto from "./assets/images/vscodesportologowhite.png";

const menuSize = 50;

const App = () => {
  useEffect(() => {
    const handleScroll = () => {
      localStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollPosition = localStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    }
  }, []);

  const { projectId } = useParams();
  const isHome = projectId === undefined; // Check if on the home route

  return (
    <Router>
      <AppWrapper>
        <Header role="banner">
          <Menu size={menuSize} />
          <Logo href="/" aria-label="Homepage">
            vscodes <LogoIcon src={logoproto} alt="Logo" />
          </Logo>
        </Header>

        <main id="main-content" role="main" tabIndex="-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:projectId" element={<ProjectDetails />} />
            <Route path="/contact" element={<Contact />} />
            {/*<Route path="*" element={<Error />} />  */}
          </Routes>
        </main>
        {/* <footer role="creator">Made by VSCD</footer> */}
      </AppWrapper>
    </Router>
  );
};

const AppWrapper = styled.div`
  /* z-index: 19;
  background-color: green;
  margin: auto; */
`;

const Header = styled.header`
  position: absolute;
  /* top: 30px; */
  width: 100vw;
  height: 80px;
  display: flex;
  z-index: 20;
  /* background-color: blue; */
  align-items: center;

  @media only screen and (min-width: 780px) {
    height: 100px;
    padding-left: 20px;
  }
`;

const Logo = styled.a`
  // Re-using the menu size as left offset will keep them in sync.
  // We also add an extmargin-left: 100px;
  /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%); */
  display: flex;
  margin-left: ${menuSize + 8}px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Roboto Mono", monospace;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.05);
  gap: 10px;

  &:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 1272px) {
    font-size: 18px;
    margin-left: 0;
    align-items: center;
  }

  @media only screen and (max-width: 790px) {
    margin: auto;
    display: flex;
    flex-direction: row;
    font-size: 24px;
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;

  @media only screen and (max-width: 767px) {
    height: 18px;
    width: auto;
    align-items: center;
  }
`;

export default App;
