import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import styles from "./TextBubbles.module.css";

const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

const getTimeBasedGreeting = () => {
  const currentHour = getCurrentTime();
  let greeting;

  if (currentHour < 12) {
    // Morning greetings
    const morningGreetings = [
      "Good morning, hope your day’s off to a great start!",
      "Morning! Ready to tackle the day?",
      "Hi there, wishing you a productive start to your day!",
    ];
    greeting =
      morningGreetings[Math.floor(Math.random() * morningGreetings.length)];
  } else if (currentHour >= 12 && currentHour < 17) {
    // Afternoon greetings
    const afternoonGreetings = [
      "Hope your afternoon’s going smoothly!",
      "Good afternoon! How’s the day treating you so far?",
      "Hi, hope your day’s shaping up well!",
    ];
    greeting =
      afternoonGreetings[Math.floor(Math.random() * afternoonGreetings.length)];
  } else {
    // Evening greetings
    const eveningGreetings = [
      "Good evening! Wrapping up a good day?",
      "Hi there, hope your evening’s off to a relaxing start!",
      "Evening! Hope you’re ending the day on a high note!",
    ];
    greeting =
      eveningGreetings[Math.floor(Math.random() * eveningGreetings.length)];
  }

  return greeting;
};

const slideInAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const MessageBox = styled.div`
  overflow: auto;
  /* width: 100vw; */
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  @media only screen and (min-width: 750px) {
    padding: 1rem 0.9rem;
  }
`;

const TextBubble = styled.div`
  overflow: hidden;
  background: rgba(216, 216, 216, 0.2);
  position: relative;
  opacity: 0; /* Initially invisible */
  animation: ${slideInAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay}s; /* Dynamic delay */
  margin: 0 0 0.35rem 0;
  max-width: 70vw;
  padding: 14px 10px;
  border-radius: 20px;

  @media only screen and (min-width: 750px) {
    padding: 1.5rem 2rem;
    line-height: 1.25rem;
    border-radius: 4rem;
  }
`;

const TextBubbles = () => {
  const [messages, setMessages] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Determine the first message dynamically based on the time of day
    const firstMessage = getTimeBasedGreeting();
    const newMessages = [
      firstMessage,
      "If you're up for a game-changing collab",
      "Or top-notch consulting",
    ];

    if (index < newMessages.length) {
      const timer = setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, newMessages[index]]);
        setIndex((prevIndex) => prevIndex + 1);
      }, 1000); // 1-second delay between messages

      return () => clearTimeout(timer); // Clear the timer on cleanup
    }
  }, [index]);

  return (
    <MessageBox className={styles.messages}>
      {messages.map((message, idx) => (
        <TextBubble
          key={`${message}_${idx}`}
          delay={idx * 0.5} // Pass delay based on index
        >
          <StyledSpan className={styles.message}>{message}</StyledSpan>
        </TextBubble>
      ))}
    </MessageBox>
  );
};

const StyledSpan = styled.span`
  font-size: 16px; /* Adjust as needed */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;

  @media only screen and (min-width: 780px) {
    font-size: 50px; /* Adjust as needed */
  }
`;

export default TextBubbles;
